import './Intro.css';
import BannerComoFunciona from '../../assets/images/banner-como-funciona.webp';

export default function Intro(props) {
    return (
        <>
            <section id="banner">
                <div className="container">
                    <div className="row">
                        <div className='col-12 isMobile'>
                            <div className='banner-box'>
                                <div className='row align-items-center'>
                                    <div className=" col-12 col-md-5 offset-md-1 animate__animated animate__backInLeft">
                                            <div className='texto'>
                                                <h1>Uma nova jornada no cuidado com sua saúde</h1>
                                                <p>Agora, você pode agendar seu atendimento diretamente com nossas marcas parceiras, garantindo a mesma qualidade e atenção onde você estiver.</p>
                                                <a href="#como-agendar" className="btn btn-primary btn-dsk animate__animated animate__pulse animate__infinite infinite" title="Agende seu atendimento">Veja como funciona</a>
                                            </div>
                                    </div>
                                    <div className='col-6 d-none d-md-block'>
                                        <img src={BannerComoFunciona} alt="Sobre" className="img-fluid img-banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}