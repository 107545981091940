import "./styles.css"
import React from 'react'
import logoPadrao from "../../assets/images/newLogoPadrao.webp"
import logoPardini from "../../assets/images/newLogoPardini.webp"
import logoAzevedo from "../../assets/images/newLogoAzevedo.webp"
import logoMedicina from "../../assets/images/newLogoMedicina.webp"


const ComoAgendar = () => {
  return (
    <section id="como-agendar">
                <div className="container">
                    <span className="dots"/>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center flex-column text-center">
                            <span>Novo formato</span>
                            <h3>Como agendar seu atendimento</h3>
                        
                            <p>
                            Para agendar seu atendimento, escolha a marca parceira desejada e acesse o site correspondente. Siga as instruções para selecionar a data e horário que melhor lhe atendem, garantindo a continuidade da qualidade no serviço.
                            </p>
                        </div>
                        <ul className='brands'>
                            <li>
                                <a href="https://www.hermespardini.com.br/" target="_blank" rel="noreferrer">
                                    <img src={logoPardini} alt="Logo Hermes Pardini"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.pauloazevedo.com.br/" target="_blank" rel="noreferrer">
                                    <img src={logoAzevedo} alt="Logo Paulo Azevedo"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.padrao.com.br/" target="_blank" rel="noreferrer">
                                    <img src={logoPadrao} alt="Logo Padrão Medicina Diagnóstica e Personalizada"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.centrodemed.com.br/" target="_blank" rel="noreferrer">
                                    <img src={logoMedicina} alt="Logo Centro de Medicina"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
  )
}

export default ComoAgendar