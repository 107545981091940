import { useEffect } from 'react';
import AreaCobertura from '../../components/AreaCobertura/AreaCobertura';
import ComoFunciona from '../../components/ComoFunciona/Home/ComoFuncionaHome';
import CondicoesPagamento from '../../components/CondicoesPagamento/CondicoesPagamento';
import Diferenciais from '../../components/Diferenciais/Diferenciais';
import Intro from '../../components/Intro/Intro';
import Sobre from '../../components/Sobre/Sobre';
import FAQ from '../../components/FAQ/Faq';
import imgSobre from '../../assets/images/sobre.png';
import imgSobreMobile from '../../assets/images/sobre-mobile1.png';
import './Home.css';
import ComoAgendar from '../../components/ComoAgendar';

export default function Home() {
    useEffect(() => {
        document.title = "Home | Saúde Mob";
    }, []);

    return <div id="page-home">
        <Intro pageName="Home"/>
        <ComoFunciona />
        <ComoAgendar/> 
        {/* <Diferenciais />
        <Sobre title="Sobre o SaúdeMob" image={imgSobre} imagemobile={imgSobreMobile} />
        <AreaCobertura />
        <CondicoesPagamento pageName="Home" />
        <FAQ /> */}
    </div>;
}
