import './ComoFuncionaHome.css';
export default function ComoFunciona() {
 
    return (
        <>
            <section id="como-funciona">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center flex-column text-center">
                            <span>Atendimento</span>
                            <h3>Mudanças no agendamento de serviços domiciliares</h3>
                        
                            <p>
                            Comunicamos que o <strong>Saúdemob</strong> está passando por uma transição e os agendamentos de serviços domiciliares agora serão feitos diretamente pelos sites ou canais de contato das nossas marcas parceiras. Estamos aqui para garantir que essa mudança ocorra de forma simples e sem interrupções no cuidado com a sua saúde.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
            )
}