import './Footer.css';
import { ReactComponent as Logo } from "../../assets/svg/logo-branco.svg";

export default function Footer() {
    return (
        <>
            <footer id='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-sm-12">
                            <div className="logo-footer">
                                 <Logo width={190} height={40}/>
                                <p>Acesso à saúde para  quem precisa, onde estiver. </p>
                                <p className='warning'>Todos os termos referente ao "atendimento em qualquer lugar", "onde você estiver" e textos similares estão de acordo com os requisitos dispostos na RDC 302/2005. </p>
                                {/* <p><strong><a href="tel:40202567" target="_blank" className="tel" rel="noreferrer">4020-2567</a></strong></p> */}

                            </div>
                            <div className="container-whatsapp">
                                <span>
                                    <h3>
                                        Contato via Whatsapp:
                                    </h3>
                                </span>
                                <div className="content-whatsapp">
                                    <div className="units">
                                        <p>
                                            Hermes Pardini MG:
                                        </p>
                                        <p>
                                            Padrão:
                                        </p>
                                        <p>
                                            Laboratório Paulo C. Azevedo:
                                        </p>
                                        <p>
                                            Centro de Medicina:
                                        </p>
                                    </div>
                                    <div className="phones">
                                        <p>
                                            <a href="https://api.whatsapp.com/send?phone=553132286200" target='_blank' rel="noreferrer">
                                                (31) 3228-6200
                                            </a>
                                        </p>
                                        <p>
                                            <a href="https://api.whatsapp.com/send?phone=556240209005" target='_blank' rel="noreferrer">
                                                (62) 4020-9005
                                            </a>
                                        </p>
                                        <p>
                                            <a href="https://api.whatsapp.com/send/?phone=559140098899&text=Olá!+Entrei+em+contato+através+do+site+e+gostaria+de+atendimento%2C+por+favor.&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
                                                (91) 4009-8899
                                            </a>
                                        </p>
                                        <p>
                                            <a href="https://api.whatsapp.com/send?phone=552140020203" target='_blank' rel="noreferrer">
                                                (21) 4002-0203
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="separator-line" />
                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                            <div className="responsaveis">
                                <h4>Responsáveis técnicos</h4>
                                <ul>
                                    <li><strong>MG:</strong> Dr. Guilherme Birchal Collares - CRMMG 35635 - Inscrição CRM 356 MG</li>
                                    <li><strong>RJ:</strong> Dra. Saula Sérgio Hamad Timene de Farias - CRM 52-42902-3 - RQE 12035</li>
                                    <li><strong>GO:</strong> Dra. Marise Cardoso Cortes - CRBM: 761</li>
                                    <li><strong>PA:</strong> Paulo Sérgio Roffé Azevedo - CRM/PA: 1091</li>
                                </ul>
                                <ul>
                                    <li><strong>MG:</strong> CNPJ 19.378.769/0001-76</li>
                                    <li><strong>RJ:</strong> CNPJ 19.378.769/0116-15</li>
                                    <li><strong>GO:</strong> CNPJ 01.588.888/0024-84</li>
                                    <li><strong>PA:</strong> CNPJ 04.978.805/0001-651</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
